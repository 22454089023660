import styled from 'styled-components';

import constants from '../../constants';

const device = {
  desktop: `(min-width:${constants.displaySizes.desktop})`,
  largeDesktop: `(min-width:${constants.displaySizes.largeDesktop})`,
  smallDesktop: `(min-width:${constants.displaySizes.smallDesktop})`,
  mobile: `(max-width:${constants.displaySizes.mobile})`,
  tablet: `(min-width:${constants.displaySizes.tablet})`,
};

interface WLTPContainerProps {
  theme: string;
}
interface MediaDisclaimerContainerProps {
  theme: string;
}
interface ContentContainerProps {
  theme: string;
}

export const ContentContainer = styled.div<ContentContainerProps>`
  background-color: ${(props) =>
    props.theme === constants.theme.darkTheme ? constants.color.black : constants.color.white};
  padding: 24px 28px;
  @media ${device.tablet} {
    padding: 24px 40px;
  }
  @media ${device.smallDesktop} {
    padding: 24px 60px;
  }
  @media ${device.desktop} {
    padding: 24px 96px;
  }
  @media ${device.largeDesktop} {
    margin: 0 auto;
    padding: 24px 336px;
    max-width: 1248px;
  }
`;

export const MediaDisclaimerContainer = styled.div<MediaDisclaimerContainerProps>`
  background-color: ${(props) =>
    props.theme === constants.theme.darkTheme ? constants.color.black : constants.color.white};
  position: relative;
  bottom: 0px;
  height: fit-content;

  a:focus {
    outline: 5px auto Highlight !important;
    outline: 5px auto -webkit-focus-ring-color !important;
  }
  p {
    color: ${(props) =>
      props.theme === constants.theme.darkTheme ? constants.color.white : constants.color.black};
  }

  span sup a {
    font-size: 10px !important;
    font-feature-settings: normal !important;
    font-weight: 500;
  }
  @media ${device.tablet} {
    display: flex;
    flex-direction: row;
    span {
      margin-right: 16px;
    }
  }
`;

export const WLTPContainer = styled.div<WLTPContainerProps>`
  background-color: ${(props) =>
    props.theme === constants.theme.darkTheme ? constants.color.black : constants.color.white};
  position: relative;
  bottom: 0px;
  height: fit-content;
  a:focus {
    outline: 5px auto Highlight !important;
    outline: 5px auto -webkit-focus-ring-color !important;
  }
  span sup a {
    font-size: 10px !important;
    font-weight: 500;
  }
  p {
    color: ${(props) =>
      props.theme === constants.theme.darkTheme ? constants.color.white : constants.color.black};
  }
  @media ${device.tablet} {
    flex-direction: row;
    span {
      margin-right: 16px;
    }
    span sup a {
      font-size: 10px !important;
      font-weight: 500;
    }
  }

  @media screen and (max-width: ${constants.displaySizes.smallDesktop}) {
    span {
      :not(:last-child)::after {
        content: '; ';
      }
    }
  }
`;
