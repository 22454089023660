/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable no-inner-declarations */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  TrackingDataV2 as TfsTrackingData,
  TrackingServiceV2,
} from '@volkswagen-onehub/audi-tracking-service';
import { FeatureServicesBindingAdditions } from '@volkswagen-onehub/oneaudi-os-adapter-tracking';
import {
  DataLayerEventAttributes,
  DataLayerEventInfo,
} from '@volkswagen-onehub/oneaudi-os-onesight';
import { Logger } from '@feature-hub/core';
import { addMissingTfsEventAttributes } from './Events';
import { TrackingHelper } from './TrackingHelper';

declare global {
  interface Window {
    digitalData: any;
    registeredServices: any;
    sendExitLinkEventCounter: number;
  }
}
export class TrackingV2Helper implements TrackingHelper {
  private trackingService: TrackingServiceV2 & FeatureServicesBindingAdditions;

  private loggerService: Logger;

  constructor(
    trackingService: TrackingServiceV2 & FeatureServicesBindingAdditions,
    loggerService: Logger,
  ) {
    this.trackingService = trackingService;
    this.loggerService = loggerService;
  }

  getTrackingService(): TrackingServiceV2 & FeatureServicesBindingAdditions {
    return this.trackingService;
  }

  private generateEvent = (
    attributes: DataLayerEventAttributes,
    eventAction: string,
    eventName: DataLayerEventInfo,
  ): TfsTrackingData => {
    return {
      event: {
        // @ts-expect-error to avoid linting error
        attributes: addMissingTfsEventAttributes(attributes),
        eventInfo: {
          eventAction,
          eventName,
        },
      },
    };
  };

  trackImpressionEvent = (ref: React.MutableRefObject<any>, updateCallback: Function) => {
    if (typeof window !== 'undefined' || typeof document !== 'undefined') {
      this.trackingService.registerImpressionTracking(ref.current, () => {
        const cta1Content = document.getElementsByClassName('ctas')[0]?.textContent;
        const cta2Content = document.getElementsByClassName('ctas')[1]?.textContent;

        const relatedElements = [
          {
            elementName: 'link',
            elementValue: `1-${cta1Content}`,
          },
          {
            elementName: 'link',
            elementValue: `2-${cta2Content}`,
          },
        ];
        const attributes = {
          componentName: 'minimalistic-typographic-teaser',
          elementName: '',
          label: '',
          pos: '',
          targetURL: '',
          value: '',
        };
        const attributeData = { ...attributes, relatedElements };

        const payload: TfsTrackingData = this.generateEvent(
          // @ts-expect-error to avoid linting error
          attributeData,
          'impression',
          'minimalistic typographic teaser - impression',
        );
        setTimeout(() => {
          const eventCount = window.digitalData?.event?.length;
          this.loggerService.info(
            `[Legacy Tracking V2 :: trackImpressionEvent] Datalayer was updated (see 'window.digitalData'), events: ${eventCount}, payload: `,
            payload,
          );
          updateCallback();
        }, 1000);

        return payload;
      });
    }
  };

  sendReadyEvent = () => {
    const attributes = {
      componentName: 'minimalistic-typographic-teaser',
    };
    const payload: TfsTrackingData = this.generateEvent(
      // @ts-expect-error to avoid linting error
      attributes,
      'feature_app_ready',
      'minimalistic typographic teaser - feature app ready',
    );
    this.trackingService.track(payload);
    let digitalData: any;
    if (typeof window !== 'undefined') {
      digitalData = window.digitalData;
    }

    const eventCount = digitalData?.event?.length;

    this.loggerService.info(
      `[Legacy Tracking V2 :: sendReadyEvent] Datalayer was updated (see 'window.digitalData'), events: ${eventCount}, payload: `,
      payload,
    );
  };

  sendClickEvent = (component: string) => {
    function elementClick() {
      if (component.includes('km')) {
        return 'click on wltp';
      }
      return 'click on footnote';
    }
    const attributes = {
      componentName: 'minimalistic-typographic-teaser',
      label: component,
      clickID: '',
      elementName: 'text link',
      value: '',
      pos: '',
    };

    const payload: TfsTrackingData = this.generateEvent(
      // @ts-expect-error to avoid linting error
      attributes,
      'anchor',
      `minimalistic typographic teaser - ${elementClick()}`,
    );
    this.trackingService.track(payload);
    let digitalData: any;
    if (typeof window !== 'undefined') {
      digitalData = window.digitalData;
    }
    const eventCount = digitalData?.event?.length;
    this.loggerService.info(
      `[Legacy Tracking V2 :: sendClickEvent] Datalayer was updated (see 'window.digitalData'), events: ${eventCount}, payload: `,
      payload,
    );
  };

  sendExitLinkEvent = (component: string, link: string) => {
    if (typeof window !== 'undefined' || typeof document !== 'undefined') {
      function callToActionContent1() {
        const cta1Content = document.getElementsByClassName('ctas');
        if (cta1Content[0].textContent === undefined) {
          return '';
        }
        return cta1Content[0].textContent;
      }
      function callToActionContent2() {
        const cta2Content = document.getElementsByClassName('ctas');
        if (cta2Content[1].textContent === undefined) {
          return '';
        }
        return cta2Content[1].textContent;
      }
      if (typeof window.sendExitLinkEventCounter === 'undefined') {
        window.sendExitLinkEventCounter = 0;
      }
      function validateURL() {
        // eslint-disable-next-line no-restricted-globals
        const isExternalURL = (url: string) => new URL(url).origin !== location.origin;

        if (isExternalURL(link)) {
          return 'exit_link';
        }
        return 'internal_link';
      }
      const exitCounter = () => {
        if (component === callToActionContent1()) {
          return 1;
        }
        if (component === callToActionContent2()) {
          return 2;
        }
        return '';
      };
      const relatedElements = [
        {
          elementName: 'link',
          elementValue: `${exitCounter()}-${component}`,
        },
      ];

      const spreadAttributes = {
        clickID: '',
        componentName: 'minimalistic-typographic-teaser',
        elementName: 'text link',
        label: component,
        value: '',
        pos: '',
        targetURL: link,
      };

      const attributeData = { ...spreadAttributes, relatedElements };

      const payload: TfsTrackingData = this.generateEvent(
        // @ts-expect-error to avoid linting error
        attributeData,
        `${validateURL()}`,
        'minimalistic typographic teaser - click',
      );

      this.trackingService.track(payload);

      let digitalData: any;
      if (typeof window !== 'undefined') {
        digitalData = window.digitalData;
      }
      const eventCount = digitalData?.event?.length;
      this.loggerService.info(
        `[Legacy Tracking V2 :: SendExitLinkEvent] Datalayer was updated (see 'window.digitalData'), events: ${eventCount}, payload: `,
        payload,
      );
    }
  };
}
