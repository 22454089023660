module.exports = {
  animationValues: {
    opacityNotShow: 0,
    opacityShow: 1,
    yAxisStartPosition: 100,
    yAxisEndPosition: 0,
  },
  answer: {
    no: 'no',
    yes: 'yes',
  },
  buttonGroups: {
    blockButtons: 'block-buttons',
    textButtons: 'text-buttons',
  },
  color: {
    black: '#000',
    white: '#fff',
  },
  ctaVariant: {
    primary: 'primary',
    secondary: 'secondary',
    text: 'text',
  },
  ctaStyle: {
    button: 'button',
    text: 'text',
  },
  displaySizes: {
    mobile: '375px',
    extraMobile: '540px',
    tablet: '768px',
    smallDesktop: '1024px',
    desktop: '1440px',
    largeDesktop: '1920px',
  },
  flexDirection: {
    column: 'column',
    row: 'row',
  },
  fontVariant: {
    copy1: 'copy1',
    display2: 'display2',
    order1: 'order1',
    order2: 'order2',
    order3: 'order3',
    order4: 'order4',
  },
  fontWeight: {
    bold: 'bold',
    normal: 'normal',
  },
  heading: {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    p: 'p',
  },
  sizes: {
    xxs: 'xxs',
    xs: 'xs',
    s: 's',
    m: 'm',
    l: 'l',
    xl: 'xl',
    xxl: 'xxl',
    xxxl: 'xxxl',
  },
  style: {
    transition: '0.5s',
    transitionTimingFunction: 'cubic-bezier(.15,0,.25,1)',
  },
  tagId: {
    cta: 'cta',
    heading: 'heading',
    subHeading: 'subHeading',
  },
  theme: {
    darkTheme: 'darkTheme',
    lightTheme: 'lightTheme',
  },
  paddingSizes: {
    largePadding: {
      mobile: '120px',
      tablet: '144px',
      smallDesktop: '180px',
      desktop: '240px',
      largeDesktop: '300px',
    },
    smallPadding: {
      mobile: '40px',
      tablet: '48px',
      smallDesktop: '60px',
      desktop: '80px',
      largeDesktop: '100px',
    },
  },
};
