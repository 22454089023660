/* eslint-disable react/function-component-definition */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
import React, { useContext } from 'react';
import { ContentServiceV1 } from '@oneaudi/content-service';
import { TrackingHelperWrapper } from '../../trackingUtils/TrackingHelperWrapper';
import MinimalisticTypographicTeaser from '../MinimalisticTypographicTeaser';
import { ContentContext } from '../../context';

import mockContent from '../../demo/editorPresets.json';

interface ClickEventProps {
  updateDatalayer: () => void;
  trackingWrapper: TrackingHelperWrapper;
  content?: any;
  contentService: ContentServiceV1 | undefined;
}

export default function ClickEvent({ updateDatalayer, trackingWrapper }: ClickEventProps) {
  const trackExitLink = (component: string, url: string) =>
    trackingWrapper.getTrackingServiceHelper().sendExitLinkEvent(component, url);
  const handleClick = (id: string) => trackingWrapper.getTrackingServiceHelper().sendClickEvent(id);

  const context = useContext(ContentContext);

  return (
    <div>
      <MinimalisticTypographicTeaser
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        content={context.content ? context.content : mockContent}
        updateDatalayer={updateDatalayer}
        trackingWrapper={trackingWrapper}
        trackExitLink={trackExitLink}
        handleClick={handleClick}
      />
    </div>
  );
}
