/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
/* eslint-disable react/require-default-props */
import React, { useEffect, useMemo, useState } from 'react';
import { TrackingServiceV2 as TfsTrackingService } from '@volkswagen-onehub/audi-tracking-service/dist/v2/types';
import { TrackingServiceV3 as AdapterTrackingService } from '@volkswagen-onehub/oneaudi-os-onesight';
import { ContentServiceV1 } from '@oneaudi/content-service';
import { Logger } from '@feature-hub/core';
import ClickEvent from './panels/ClickEvent';
import {
  getNextId,
  TrackingHelperWrapper,
  TrackingServiceEnvironment,
} from '../trackingUtils/TrackingHelperWrapper';

export interface FeatureAppRootProps {
  content?: any;
  contentService: ContentServiceV1 | undefined;
  trackingService?: TfsTrackingService | AdapterTrackingService;
  loggerService: Logger;
  // localeService: LocaleServiceV1;
  environment: string;
}

const isFeatureAppDefinedInTrackingService = (
  trackingServiceWrapper: TrackingHelperWrapper | undefined,
  featureAppId: string,
): boolean => {
  return (
    trackingServiceWrapper
      ?.getTrackingService()
      ?.getDatalayer()
      ?.component?.findIndex(
        (app: { componentInfo: { componentID: string } }) =>
          app?.componentInfo?.componentID === featureAppId,
      ) !== -1
  );
};

const getServiceWrapper = (
  environment: string,
  trackingService: TfsTrackingService | AdapterTrackingService | undefined,
  loggerService: Logger,
  featureAppId: string,
) => {
  let trackingServiceWrapper: TrackingHelperWrapper | undefined;

  if (environment) {
    loggerService.debug(`Initializing environment: ${environment}`);

    try {
      trackingServiceWrapper = new TrackingHelperWrapper(
        loggerService,
        environment as TrackingServiceEnvironment,
        featureAppId,
      );
    } catch (err) {
      loggerService.warn(
        `Invalid or undefined tracking service type: ${environment}. Default source will be used.`,
        err,
      );
    }
  } else {
    loggerService.debug("Query parameter 'tracking' not recognized. Initializing default service.");
    trackingServiceWrapper = new TrackingHelperWrapper(
      loggerService,
      'tfs' as TrackingServiceEnvironment,
      featureAppId,
      trackingService,
    );
  }

  return trackingServiceWrapper;
};

const FeatureAppRoot: React.FC<FeatureAppRootProps> = ({
  trackingService,
  loggerService = console,
  environment,
  content,
  contentService,
}) => {
  const [serviceInstance, setServiceInstance] = useState<TrackingHelperWrapper>();
  const featureAppId = useMemo(
    () => trackingService?.featureAppId ?? getNextId(__FEATURE_APP_NAME__),
    [],
  );

  const [_dataLayerChanges, setDataLayerChanges] = useState([{}]);
  const [_isAppRegistered, setIsAppRegistered] = useState(false);

  const updateDatalayer = () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const dataLayerCopy = JSON.parse(
      JSON.stringify(serviceInstance?.getTrackingService()?.getDatalayer() || {}),
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    setDataLayerChanges((prev) => [...prev, dataLayerCopy]);
  };

  useEffect(() => {
    let paramsString;
    let searchParams: any;

    if (typeof window !== 'undefined') {
      paramsString = window.location.search;
      searchParams = new URLSearchParams(paramsString);
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    if (!searchParams.has('tracking') || !serviceInstance) {
      // If there is a tracking service environment configured in Contentful,
      // we generate a corresponding service,
      // otherwise, we provide the default service based on environment
      const trackingServiceWrapper = getServiceWrapper(
        environment,
        trackingService,
        loggerService,
        featureAppId,
      );

      if (trackingServiceWrapper) {
        if (serviceInstance) {
          loggerService.debug(
            `Tracking Service was re-registered. Previous source: ${serviceInstance.getTrackingServiceSource()}, current source: ${trackingServiceWrapper.getTrackingServiceSource()}.`,
          );
        } else {
          trackingServiceWrapper.getTrackingServiceHelper().sendReadyEvent();
        }

        setServiceInstance(trackingServiceWrapper);
        setIsAppRegistered(isFeatureAppDefinedInTrackingService(serviceInstance, featureAppId));
        updateDatalayer();
      }
    }
  }, []);

  return (
    <div data-test-id="feature-app-root-component">
      <div>
        <ClickEvent
          data-test-id="navigation-mtt"
          trackingWrapper={serviceInstance!}
          updateDatalayer={updateDatalayer}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          content={content}
          contentService={contentService}
        />
      </div>
    </div>
  );
};

export default FeatureAppRoot;
