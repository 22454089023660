/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  TrackingServiceV3,
  DataLayerComponent,
  TrackingEvent,
} from '@volkswagen-onehub/oneaudi-os-onesight';
import { Logger } from '@feature-hub/core';
import { TrackingHelper } from './TrackingHelper';

export class TrackingV3Helper implements TrackingHelper {
  private trackingService: TrackingServiceV3;

  private loggerService: Logger;

  constructor(trackingService: TrackingServiceV3, loggerService: Logger) {
    this.trackingService = trackingService;
    this.loggerService = loggerService;
  }

  getTrackingService(): TrackingServiceV3 {
    return this.trackingService;
  }

  sendReadyEvent = () => {
    const payload: TrackingEvent = {
      attributes: {
        componentName: '',
      },
      eventInfo: {
        eventAction: 'feature_app_ready',
        eventName: 'minimalistic typographic teaser - feature app ready',
      },
    };

    this.trackingService.track(payload);

    let digitalData: any;
    if (typeof window !== 'undefined') {
      digitalData = window.digitalData;
    }

    const eventCount = digitalData?.event?.length;
    this.loggerService.info(
      `[Legacy Tracking V3 :: sendReadyEvent] Datalayer was updated (see 'window.digitalData'), events: ${eventCount}, payload: `,
      payload,
    );
  };

  sendClickEvent = (component: string) => {
    const payload: TrackingEvent = {
      attributes: {
        componentName: '',
        label: component,
        targetUrl: '',
        clickID: '',
        elementName: 'text link',
        value: component,
        pos: '',
      },
      eventInfo: {
        eventAction: 'internal_link',
        eventName: 'minimalistic typographic teaser - click',
      },
    };

    this.trackingService.track(payload);

    let digitalData: any;
    if (typeof window !== 'undefined') {
      digitalData = window.digitalData;
    }
    const eventCount = digitalData?.event?.length;
    this.loggerService.info(
      `[Legacy Tracking V3 :: sendClickEvent] Datalayer was updated (see 'window.digitalData'), events: ${eventCount}, payload: `,
      payload,
    );
  };

  sendTrackInternalNavigationEvent = (component: string) => {
    const payload: TrackingEvent = {
      attributes: {
        clickID: component,
        componentName: '',
        elementName: 'text link',
        label: `Menu > ${component}`,
        targetUrl: `#${component}`,
      },
      eventInfo: {
        eventAction: 'navigation',
        eventName: 'minimalistic typographic teaser - click',
      },
    };

    this.trackingService.trackInternalNavigation(
      payload,
      `${window.location.href ?? ''}#${component}`,
    );

    let digitalData: any;
    if (typeof window !== 'undefined') {
      digitalData = window.digitalData;
    }
    const eventCount = digitalData?.event?.length;
    this.loggerService.info(
      `[Legacy Tracking V3 :: sendTrackInternalNavigationEvent] Datalayer was updated (see 'window.digitalData'), events: ${eventCount}, payload: `,
      payload,
    );
  };

  sendExitLinkEvent = (component: string, link: string) => {
    const payload: TrackingEvent = {
      attributes: {
        clickID: '',
        componentName: '',
        elementName: 'text link',
        targetUrl: link,
        label: component,
        currentURL: '',
        value: 'component',
        pos: '',
      },
      eventInfo: {
        eventAction: 'exit_link',
        eventName: 'minimalistic typographic teaser - click',
      },
    };

    this.trackingService.track(payload);

    let digitalData: any;
    if (typeof window !== 'undefined') {
      digitalData = window.digitalData;
    }
    const eventCount = digitalData?.event?.length;
    this.loggerService.info(
      `[Legacy Tracking V3 :: sendExitLinkEvent] Datalayer was updated (see 'window.digitalData'), events: ${eventCount}, payload: `,
      payload,
    );
  };

  trackImpressionEvent = (ref: React.MutableRefObject<any>, updateCallback: Function) => {
    const createPayload = (): TrackingEvent => {
      return {
        attributes: {
          componentName: '',
          elementName: '',
          value: '',
        },
        eventInfo: {
          eventAction: 'impression',
          eventName: 'minimalistic typographic teaser - impression',
        },
      };
    };

    const createCallback = (payload: TrackingEvent): (() => TrackingEvent) => {
      // needs to wrapped in timeout to make it work in the "diff component", so that it is
      // triggered AFTER the lib executed the returned payload in the track() call
      return () => {
        setTimeout(() => {
          let digitalData: any;
          if (typeof window !== 'undefined') {
            digitalData = window.digitalData;
          }
          const eventCount = digitalData?.event?.length;
          this.loggerService.info(
            `[Legacy Tracking V3 :: trackImpressionEvent] Datalayer was updated (see 'window.digitalData'), events: ${eventCount}, payload: `,
            payload,
          );
          updateCallback();
        }, 1000);

        return payload;
      };
    };

    const defaultPayload = createPayload();
    const customPayload = createPayload();

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    this.trackingService.registerImpressionTracking(ref.current, createCallback(defaultPayload), {
      delay: 10000,
      createImpressionEvent: createCallback(customPayload),
    });
  };

  updateComponent = (teaser: string) => {
    const payload: DataLayerComponent = {
      category: {
        primaryCategory: 'accx',
      },
      // @ts-expect-error to avoid linting error
      attributes: {
        teaserTitle: teaser,
      },
    };

    this.trackingService.updateComponent(payload);
    let digitalData: any;
    if (typeof window !== 'undefined') {
      digitalData = window.digitalData;
    }
    const eventCount = digitalData?.event?.length;
    this.loggerService.info(
      `[Legacy Tracking V3 :: updateComponent] Datalayer was updated (see 'window.digitalData'), events: ${eventCount}, payload: `,
      payload,
    );
  };
}
