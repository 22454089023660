/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext } from 'react';
import { VueFormatterServiceInterfaceV1 } from '@oneaudi/vue-formatter-service';
import type { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';
import { AudiFootnoteRefernceServiceScopeManagerInterfaceV3 } from '@oneaudi/footnote-reference-service';
import { IAuthServiceV1 } from '@oneaudi/audi-auth-service';
// eslint-disable-next-line
import { IPersonalizationServiceV2 } from '@volkswagen-onehub/audi-personalization-service';

type ContentContextState = {
  featureAppId: string;
  personalizationService?: IPersonalizationServiceV2;
  content?: any;
  trackingService?: any;
  authService: IAuthServiceV1;
  loggerService?: any;
  localeService: GfaLocaleServiceV1;
  // vueFormatterService: VueFormatterFeatureServiceInterfaceV1; TODO: Confirm the below is the correct replacement
  vueFormatterService: VueFormatterServiceInterfaceV1;
  footnoteReferenceService: AudiFootnoteRefernceServiceScopeManagerInterfaceV3;
};

export const ContentContext = createContext<ContentContextState>({
  featureAppId: '',
  authService: {} as IAuthServiceV1,
  loggerService: console,
  localeService: {} as GfaLocaleServiceV1,
  // vueFormatterService: {} as VueFormatterFeatureServiceInterfaceV1 TODO: Confirm the below is the correct replacement
  vueFormatterService: {} as VueFormatterServiceInterfaceV1,
  footnoteReferenceService: {} as AudiFootnoteRefernceServiceScopeManagerInterfaceV3,
});
