/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
import React from 'react';
import {
  audiDarkTheme,
  audiLightTheme,
  Button,
  Layout,
  LayoutItem,
  Text,
  ThemeProvider,
} from '@audi/audi-ui-react';

import type { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';
import { Motion, spring } from 'react-motion';
import { renderTextWithFootnotesReferences } from '@oneaudi/feature-app-utils';
import { UeElement } from '@oneaudi/falcon-tools';
import {
  Container,
  ContentContainer,
  CtaContainer,
  AnimatedHeadingContainer,
  AnimatedSubHeadingContainer,
  SubHeadingContent,
  TextContainer,
  ButtonContainer,
  Wrapper,
} from './style';
import { WLTPComponent } from '../Wltp';
import { TrackingHelperWrapper } from '../../trackingUtils/TrackingHelperWrapper';

import constants from '../../constants';

export interface MinimalisticTypographicTeaserProps {
  content?: any;
  localeService?: LocaleServiceV1;
  updateDatalayer: () => void;
  trackingWrapper: TrackingHelperWrapper;
  trackExitLink: Function;
  handleClick: Function;
}

const MinimalisticTypographicTeaser: React.FC<MinimalisticTypographicTeaserProps> = (
  props: any,
) => {
  const [isHeadingAnimationTriggered, setHeadingIsAnimationTriggered] = React.useState(false);
  const divRef = React.useRef<HTMLDivElement | null>(null);
  const ref = React.useRef(null);
  const { content, trackingWrapper, updateDatalayer } = props;

  const { opacityNotShow, opacityShow, yAxisStartPosition, yAxisEndPosition } =
    constants.animationValues;
  const { normal } = constants.fontWeight;
  const { column } = constants.flexDirection;
  const { display2 } = constants.fontVariant;
  const { p } = constants.heading;
  const { l, xxl } = constants.sizes;
  const { primary, secondary, text } = constants.ctaVariant;
  const { yes } = constants.answer;

  const {
    animateSection,
    justificationSection,
    legalText,
    paddingSection,
    sectionHeaderCTAs,
    sectionHeaderContent,
    themeContent,
  } = content;

  function useOnScreen(options: any) {
    const [visible, setVisible] = React.useState(false);

    React.useEffect(() => {
      if (ref.current && trackingWrapper) {
        trackingWrapper?.getTrackingServiceHelper().trackImpressionEvent(ref, updateDatalayer);
      }
    }, [ref, trackingWrapper]);

    React.useEffect(() => {
      const observer = new IntersectionObserver(([entry]) => {
        setVisible(entry.isIntersecting);
        if (visible) {
          setHeadingIsAnimationTriggered(visible);
        }
      }, options);

      if (divRef.current) {
        observer.observe(divRef.current);
      }

      return () => {
        if (divRef.current) {
          observer.unobserve(divRef.current);
        }
      };
    }, [divRef, options]);
    return [visible];
  }

  useOnScreen({
    root: null,
    threshold: 0.63,
  });
  // eslint-disable-next-line no-console
  const isBrowser = typeof window !== 'undefined';

  const renderHeadline = () => {
    const headlineContent = (
      <Text
        as={sectionHeaderContent.headlineTag}
        variant={display2 as 'display2'}
        spaceStackEnd={l as 'l'}
      >
        <span
          onClick={(e) => {
            const id = (e.target as HTMLElement).innerText;
            props.handleClick(id);
          }}
        >
          <UeElement type="text" property="headlineText" label="Headline">
            {renderTextWithFootnotesReferences(sectionHeaderContent.headline)}
          </UeElement>
        </span>
      </Text>
    );

    if (animateSection.animate === yes) {
      return (
        <Motion
          defaultStyle={{
            yAxis: isBrowser ? yAxisStartPosition : yAxisEndPosition,
            opacity: isBrowser ? opacityNotShow : opacityShow,
          }}
          style={{
            yAxis: !isHeadingAnimationTriggered
              ? spring(isHeadingAnimationTriggered ? yAxisEndPosition : yAxisStartPosition)
              : yAxisEndPosition,
            opacity: !isHeadingAnimationTriggered
              ? spring(isHeadingAnimationTriggered ? opacityShow : opacityNotShow)
              : opacityShow,
          }}
        >
          {(style: any) => (
            <AnimatedHeadingContainer
              id="heading-id-container"
              opacity={style.opacity}
              translateY={style.yAxis}
              justification={justificationSection.justification}
              className={isHeadingAnimationTriggered ? 'animate-heading' : ''}
            >
              {headlineContent}
            </AnimatedHeadingContainer>
          )}
        </Motion>
      );
    }

    return headlineContent;
  };

  const renderSubheadOrCopy = () => {
    // ** As per the new logic we have switched labels and rules between **Subheadline** and **Copy** so all previous
    // ** content will respect old styling.
    // ** Subheadline will render as copy and Copy will be render as Subheadline
    // ** WEBART-4759 - https://collaboration.msi.audi.com/jira/browse/WEBART-4759

    const isCopy = () => {
      if (
        sectionHeaderContent &&
        sectionHeaderContent.subhead &&
        sectionHeaderContent?.subhead !== ''
      ) {
        return true;
      }
      return false;
    };

    const subheadContent = (
      <LayoutItem>
        <Text as={p as 'p'} variant={isCopy() ? 'copy1' : 'order3'} weight={normal as 'normal'}>
          <SubHeadingContent textAlignment={justificationSection.justification}>
            <UeElement
              type="text"
              property={isCopy() ? 'subheadlineText' : 'copy'}
              label={isCopy() ? 'Subheadline' : 'Copy'}
            >
              {renderTextWithFootnotesReferences(
                isCopy() ? sectionHeaderContent.subhead : sectionHeaderContent.copy,
              )}
            </UeElement>
          </SubHeadingContent>
        </Text>
      </LayoutItem>
    );

    if (animateSection.animate === yes) {
      return (
        <Motion
          defaultStyle={{
            yAxis: isBrowser ? yAxisStartPosition : yAxisEndPosition,
            opacity: isBrowser ? opacityNotShow : opacityShow,
          }}
          style={{
            yAxis: !isHeadingAnimationTriggered
              ? spring(isHeadingAnimationTriggered ? yAxisEndPosition : yAxisStartPosition)
              : yAxisEndPosition,
            opacity: !isHeadingAnimationTriggered
              ? spring(isHeadingAnimationTriggered ? opacityShow : opacityNotShow)
              : opacityShow,
          }}
        >
          {(style: any) => (
            <AnimatedSubHeadingContainer
              id="sub-heading-id-container"
              justification={justificationSection.justification}
              opacity={style.opacity}
              translateY={style.yAxis}
              className={isHeadingAnimationTriggered ? 'animate-subheading' : ''}
            >
              <span
                onClick={(e) => {
                  const id = (e.target as HTMLBodyElement).text;
                  props.handleClick(id);
                }}
              >
                {subheadContent}
              </span>
            </AnimatedSubHeadingContainer>
          )}
        </Motion>
      );
    }

    return subheadContent;
  };

  const ctaExists = (ctaNum: number) => {
    const cta = sectionHeaderCTAs[`cta${ctaNum}`];

    return Boolean(cta.text && cta.link);
  };

  const ctasToRender = () => {
    const ctas: any = [];
    const ctaKeys = ['cta1', 'cta2'];

    ctaKeys.forEach((ctaKey, index) => {
      const currentCTA = sectionHeaderCTAs[ctaKey];
      const ctaStyleIsText = sectionHeaderCTAs.style === constants.ctaStyle.text;

      if (ctaExists(index + 1)) {
        const ContainerType = ctaStyleIsText ? TextContainer : ButtonContainer;
        const buttonVariant = index === 0 ? primary : secondary;
        const clickableTextOrButton = ctaStyleIsText ? text : buttonVariant;
        const setNewWindow = (input: string | boolean) => {
          if (typeof input !== 'boolean') {
            return currentCTA.openLinkInNewWindow === 'newTab';
          }
          return currentCTA.openLinkInNewWindow;
        };

        ctas.push(
          <ContainerType key={`${ctaKey}-text`} isPrimary={index === 0}>
            <Button
              aria-label={currentCTA.ctaArialLabel}
              id={`component-${ctaKey} ctas`}
              className={`component-${ctaKey} ctas`}
              href={currentCTA.link}
              newWindow={setNewWindow(currentCTA.openLinkInNewWindow)}
              variant={clickableTextOrButton as 'primary' | 'secondary' | 'text'}
              stretch={!ctaStyleIsText}
              onClick={(e) => {
                const id = e.currentTarget?.textContent || '';
                const href = e.currentTarget?.href;
                props.trackExitLink(id, href);
              }}
            >
              <UeElement
                type="text"
                property={`cta${index + 1}Text`}
                label={`CTA Label (${index + 1})`}
              >
                {currentCTA.text}
              </UeElement>
            </Button>
          </ContainerType>,
        );
      }
    });

    if (animateSection.animate === yes) {
      return (
        <Motion
          defaultStyle={{
            yAxis: isBrowser ? yAxisStartPosition : yAxisEndPosition,
            opacity: isBrowser ? opacityNotShow : opacityShow,
          }}
          style={{
            yAxis: !isHeadingAnimationTriggered
              ? spring(isHeadingAnimationTriggered ? yAxisEndPosition : yAxisStartPosition)
              : yAxisEndPosition,
            opacity: !isHeadingAnimationTriggered
              ? spring(isHeadingAnimationTriggered ? opacityShow : opacityNotShow)
              : opacityShow,
          }}
        >
          {(style: any) => (
            <CtaContainer
              id="ctas-container"
              opacity={style.opacity}
              translateY={style.yAxis}
              ctaStyle={sectionHeaderCTAs.style}
              isSingleCta={!sectionHeaderCTAs?.cta2?.text}
              className={isHeadingAnimationTriggered ? 'animate-ctas' : ''}
            >
              {ctas}
            </CtaContainer>
          )}
        </Motion>
      );
    }

    return ctas;
  };

  return (
    <Wrapper
      color="primary"
      data-test-id="impression-tracking-box"
      id="impression-tracking-box"
      ref={ref}
    >
      <ThemeProvider
        theme={themeContent.theme === constants.theme.darkTheme ? audiDarkTheme : audiLightTheme}
      >
        <Container
          ref={divRef}
          className="component-container"
          padding={paddingSection.padding}
          theme={themeContent.theme}
          ctaType={sectionHeaderCTAs.style}
          justification={justificationSection.justification}
          data-test-id="mtt"
        >
          <ContentContainer>
            <Layout justify={justificationSection.justification} direction={column as 'column'}>
              <LayoutItem align={justificationSection.justification}>{renderHeadline()}</LayoutItem>
              <LayoutItem align={justificationSection.justification}>
                {renderSubheadOrCopy()}
              </LayoutItem>
            </Layout>
          </ContentContainer>
          {ctaExists(1) || ctaExists(2) ? (
            <Layout justify={justificationSection.justification} spaceStackStart={xxl as 'xxl'}>
              <LayoutItem>
                <Layout
                  className="mtt-cta-container"
                  direction={sectionHeaderCTAs.style === 'button' ? 'row' : 'column'}
                >
                  {ctasToRender()}
                </Layout>
              </LayoutItem>
            </Layout>
          ) : (
            <div />
          )}
        </Container>

        {legalText?.wltpKey?.length || legalText?.mediaDisclaimer !== '' ? (
          <WLTPComponent
            wltpTextValues={legalText?.wltpKey.length > 0 ? legalText.wltpKey : null}
            theme={themeContent?.theme}
            mediaDisclaimer={legalText?.mediaDisclaimer ? legalText?.mediaDisclaimer : null}
          />
        ) : (
          <div />
        )}
      </ThemeProvider>
    </Wrapper>
  );
};

export default MinimalisticTypographicTeaser;
